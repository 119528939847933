@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      calc(-250px * 17)
    ); /* Match the number of logos and their width */
  }
}

.slider {
  height: 100px;
  margin: auto;
  overflow-x: hidden;
  position: relative;
  width: 207vh;
}

.slide-track {
  display: flex;
  width: calc(250px * 34); /* Width for two sets of logos */
  animation: scroll 80s linear infinite;
}

.slide {
  height: 100px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
