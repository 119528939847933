* {
  font-family: 'Montserrat', 'sans-serif';
  letter-spacing: -0.02em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  object-fit: cover;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

span {
  display: inline-block;
  white-space: nowrap;
}

main {
  min-height: 100%;
}

h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 73px;
  letter-spacing: -0.02em;
  text-transform: none;
}

h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 66px;
  letter-spacing: -0.02em;
  text-transform: none;
}

h3 {
  font-style: normal;
  font-size: 36px;
  letter-spacing: -0.02em;
  text-transform: none;
}

h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 49px;
  letter-spacing: -0.02em;
  text-transform: none;
}

h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-transform: none;
  letter-spacing: -0.02em;
}

h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-transform: none;
  letter-spacing: -0.02em;
}

input[type='text'],
input[type='email'],
select.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

input[type='text']:focus,
input[type='email']:focus,
select.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.flexContainer {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.years_h1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  /* identical to box height */

  letter-spacing: -0.02em;

  color: #252525;
}

.years_p {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  /* or 139% */

  color: #272727;
}

.grow {
  /* transition: 0.4s ease; */
  /* transition: all 0.1s ease-in-out; */
  transition: 500ms transform cubic-bezier(0.19, 1, 0.22, 1),
    350ms box-shadow linear;
}

.grow:hover {
  /* transform: scale(1.025);
  -webkit-transform: scale(1.025);
  -ms-transform: scale(1.025);
  transition-duration: 0.1s; */
  transform: scale(1.041666);
  -webkit-transform: scale(1.041666);
  -ms-transform: scale(1.041666);
  transition-duration: 330ms;
  transition-timing-function: scale(1.041666) scale(1.041666) (0.19, 1, 0.22, 1);
}

.blueHover {
  transition: 0.3s color;
}

.blueHover:hover {
  color: #25aae1;
  cursor: pointer;
}

.redHover {
  transition: 0.3s color;
}

.redHover:hover {
  color: #ed1c24;
  cursor: pointer;
}
.btn-info {
  font-size: 14px;
  color: rgba(0, 46, 108, 1);

  letter-spacing: 0px;
  line-height: 5px;
  border: 2px solid rgba(0, 46, 108, 1);
  border-radius: 18px;
  background: transparent;
  transition: all 0.3s ease 0s;
  text-transform: none;
}

.btn-info:hover {
  color: #fff;
  background: rgba(0, 46, 108, 1);
  border: 2px solid rgb(1, 28, 63);
}

.footer-btn-info {
  font-size: 16px;
  color: white;

  letter-spacing: 1px;
  line-height: 12px;
  border: 2px solid white;
  border-radius: 18px;
  background: transparent;
  transition: all 0.3s ease 0s;
  text-transform: none;
}

.footer-btn-info:hover {
  color: #fff;
  background: rgba(0, 46, 108, 1);
  border: 2px solid rgb(1, 28, 63);
}

.navBar {
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 79, 255, 0.1);
  /* height: 119px; */
  background-color: black !important;
  opacity: 95%;
}

.navRight {
  margin-bottom: -10px;
}

.navBarLinks {
  margin-right: -10px;
}

.navLink {
  text-transform: none !important;
  font-weight: 400;
  color: white;
  margin: 0 0 0 9px;
  font-size: 20px;
  white-space: nowrap;
  font-weight: 500;
  font-size: 17px;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
@media only screen and (max-width: 992px) {
  .navFont {
    font-size: 28px !important;
  }
}

.navbar-brand {
  display: flex;
}

.nav_jxLogo {
  width: 128px;
  height: 49px;
  background-image: url('./img/misc/jxdesign.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

header a {
  text-decoration: none;
}

/* @footer */

footer {
  background: #002e6c;
  color: white;
  padding-top: 115px;
  padding-bottom: 89px;
}

.footer_email_phone_container {
  display: flex;
  flex-direction: column;
}

footer i {
  color: white;
}

footer a {
  color: white;
  text-decoration: none;
}

.footerContainer {
  display: flex;
  width: 1087px;
  margin: 0 auto;
}

.footerTopRight {
  margin-top: 25px;
  text-align: left;
  font-size: 30px;
  display: flex;
}

.footerTopRight > a {
  margin-right: 26px;
}

.footerAddress {
  margin-top: 65px;
}

.footerLinks {
  margin-top: 64px;
}

.footerLinks > a {
  margin-bottom: 9px;
  font-weight: 500;
  font-size: 18px;
}

.footerLocations {
  display: inherit;
}

.footerLocations > div:first-child,
.footerLocations > li:first-child {
  font-weight: 500;
}

.footerLocations > div,
.footerLocations > li {
  margin-right: 20px;
  font-size: 16px;
}

.footer_h1 {
  white-space: nowrap;

  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
  /* identical to box height */

  letter-spacing: -0.04em;

  color: #ffffff;
}

.footer_email {
  text-decoration: underline;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.018em;
  line-height: 68px;
  transition: all 0.4s ease;
}

.footer_phone {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.1em;

  margin-top: 33px;
  white-space: nowrap;
  word-wrap: none;
  transition: all 0.4s ease;
}

.footer_p {
  font-weight: 300;
  font-size: 20px;
  /* or 145% */

  letter-spacing: 0.025em;

  white-space: nowrap;
  word-wrap: none;
}

.footer_copyright {
  font-size: 16px;
}

.footer_copyrightContainer {
  align-self: flex-end;
  text-align: center;
  font-weight: 200;
  font-size: 30px;
}

.footer_jxLogo {
  width: 210.15px;
  height: 77.28px;
  object-fit: contain;
}

.footer_loonumLogo {
  width: 311px;
  height: 28px;
}

.thinIcon::before {
  -webkit-text-stroke: 0.5px #fff;
}

.whatBox {
  position: relative;
}

.whatLink {
  position: absolute;
  bottom: 0;
  right: 18px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #252525;
  text-decoration: none;

  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;

  transition: all 0.3s ease;
}

.whatLink:hover {
  filter: invert(72%) sepia(77%) saturate(781%) hue-rotate(161deg)
    brightness(100%) contrast(88%);
}

.whatLink > p {
  margin: 0 7px 0 0;
}

.reveal {
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.4s ease;
}

.reveal.active {
  transform: translateY(0px);
  opacity: 1;
}

.dropReveal {
  transform: matrix(1, 0, 0, 1, 0, -30);
  opacity: 0;
  transition: all 0.4s ease;
  will-change: opacity, transform;
}

.isCollapsed .dropReveal {
  transform: matrix(1, 0, 0, 1, 0, 0);
  opacity: 1;
}

.homepage_heading_title {
  padding-top: 168px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fa-chevron-down {
  transition: 0.67s transform cubic-bezier(0.19, 1, 0.22, 1);
}

.isCollapsed.fa {
  transform: rotate(180deg);
}

.homepage_heading_h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 59px;
  letter-spacing: -0.02em;

  color: #252525;
}
/* 
.homepage_heading_h1.show span {
  opacity: 1;
  transform: none;
}

.homepage_heading_h1 span {
  opacity: 0;
  transform: translate3d(0, 50px, 0);
  will-change: transform, opacity;
  z-index: 1;
}

.homepage_heading_h1 span:nth-child(1) {
  transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 283ms,
    transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 283ms;
}
.homepage_heading_h1 span:nth-child(2) {
  transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 343ms,
    transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 343ms;
}
.homepage_heading_h1 span:nth-child(3) {
  transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 403ms,
    transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 403ms;
} */

.homepage_heading_ipadStockPhoto {
  padding-top: 62px;
  padding-bottom: 62px;
  border-radius: 5px;
  overflow: hidden;
}

.homepage_what {
  display: flex;
  margin-top: 121px;
  white-space: nowrap;
}

.homepage_what_h4 {
  font-weight: 700;
  color: #252525;
  line-height: 48px;
  margin-right: 196px;
}

.homepage_what_title {
  height: 34px;
  color: #272727 !important;
  font-weight: 500;
  font-size: 25px;
}

.homepage_what_description_div {
  padding-top: 19px;
  padding-bottom: 11px;
}

.homepage_what_description {
  /* padding-top: 16px; */
  white-space: nowrap;
}

.homepage_what_description_p {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  overflow: hidden;
  /* or 150% */
  letter-spacing: 0.007em;
  color: #272727;
  transition: all 0.4s ease;

  margin: 0;

  height: 0;
}

.homepage_cases {
  margin-top: 306px;
}

.homepage_cases_h4 {
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  /* identical to box height */

  color: #252525;

  margin-left: 98px;
}

.homepage_cases_title {
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  margin-top: 22px;
}

.homepage_cases_container {
  margin-top: 70px;
  margin-left: 142px;
  padding: 0px;
  display: flex;
}

.homepage_cases_container.casestudies {
  margin-top: 110px;
}

.homepage_cases_container > div:first-child {
  margin-right: 114px;
}

.homepage_cases_img {
  background-size: cover !important;
  border-radius: 5px;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  -ms-interpolation-mode: 'nearest-neighbor';
  image-rendering: -webkit-optimize-contrast;
  width: 430px;
  cursor: pointer;
}

.homepage_cases_subtitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: rgba(37, 37, 37, 0.7);
  margin-top: 9px;

  opacity: 0;
  transition: all 0.4s ease;
}

.homepage_cases_subtitle.hover {
  opacity: 1;
}

.homepage_cases_section {
  flex: 50%;
}

.homepage_clients {
  margin-top: 121px;
  margin-left: 98px;
}

.homepage_clients_h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  /* identical to box height */

  letter-spacing: -0.02em;

  color: #252525;
}

.homepage_clients_logoContainer {
  position: relative;
  /*  display: grid; */
  /* grid-template-columns: repeat(6, 1fr); */
  /* gap: 100px 70px; */
  margin-top: 100px;
  white-space: nowrap;
}

.homepage_aboutus {
  margin-top: 103px;
  padding-bottom: 108px;
  display: flex;
}

.homepage_aboutus > div:first-child {
  margin-right: 248apx;
}

.homepage_aboutus_h1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  /* identical to box height */

  letter-spacing: -0.02em;
  color: #252525;
  margin-bottom: 45px;
}

.homepage_aboutus_p {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* or 150% */

  letter-spacing: 0.007em;

  color: #272727;
}

.homepage_process {
  margin-top: 79px;
  margin-left: 98px;
}

.homepage_process_h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  /* identical to box height */

  text-transform: capitalize;

  color: #252525;
  margin-bottom: 49px;
}

.homepage_process_p {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* or 150% */

  letter-spacing: 0.007em;

  color: #272727;
}

.homepage_process_processInfographic {
  margin-top: 122px;
}

.aboutus_founder {
  margin-top: 129px;
  display: flex;
  margin-bottom: 105px;
}

.aboutus_founder_h1 {
  font-size: 40px;
  font-weight: 700;
  transition-delay: 200ms;
  line-height: 30px;
}

.aboutus_founder_content {
  width: 480px;
}

.about_founder_img {
  margin-right: 74px;
}

.about_founder_img img {
  border-radius: 5px;
  width: 430px;
  height: 460px;
}

.about_founder_description {
  white-space: nowrap;
  margin-top: 28px;

  font-weight: 500;
  font-size: 16px;
  line-height: 27px;

  letter-spacing: 0.007em;

  color: #272727;
}

.about_w1460 {
  width: 958px;
}

.about_team {
  margin-top: 104px;
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px; */
}

.about_team_h3 {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;

  color: #252525;
  white-space: nowrap;
  align-self: baseline;
  margin-left: 10px;
}

.about_team_img {
  width: 968px;
  height: 394px;
  border-radius: 5px;
  opacity: 1;
}

.about_team_img_location {
  transition: all 0.4s ease;
}

.about_team_img_location.hidden {
  opacity: 0;
}

.about_team_img_location:hover {
  opacity: 0;
}

.about_team_text {
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #252525;
  margin-top: 18px;
  margin-bottom: 0px;
  margin-left: 20px;
}

.about_team_subtitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: rgba(37, 37, 37, 0.7);
  opacity: 0;
  transition: all 0.4s ease;
  margin-left: 20px;
}

.about_team_subtitle.hover {
  opacity: 1;
}

.about_values {
  margin-top: 126px;
  width: 100%;
}

.about_values_h3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  /* identical to box height */

  letter-spacing: -0.02em;

  color: #252525;
}

.about_values_map {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 83px;
  row-gap: 30px;
  width: 75%;
  margin: 0 auto;
}

.about_values_title {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: #252525;
}

.about_values_description {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.007em;

  color: #252525;
  width: 373px;
  height: 193px;
  white-space: nowrap;
}

.about_process_stats {
  display: flex;
}

.about_process_stats_h1 {
  font-weight: 600;
  font-size: 64px;
  line-height: 78px;
  /* identical to box height */
  letter-spacing: -0.02em;
  color: #252525;
  margin-bottom: 29px;
}

.about_process_stats_p {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  /* identical to box height, or 156% */

  color: #272727;
}

.about_process_processInforgraphic {
  margin-top: 68px;
  margin-left: -45px;
}

.clientFooter {
  height: 780px;
  background: white;
  /* padding-top: 92px; */
}

.clientFooter_section {
  /* display: flex; */
  width: 1087px;
  margin: 0 auto;
}

.clientFooter_h5 {
  font-weight: 700;
  font-size: 40px;
  letter-spacing: -0.02em;

  color: black;
}

.clientFooter_logoContainer {
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.faq {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.faq_container {
  margin-top: 129px;
  margin-bottom: 63px;
}

.faq_h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 59px;
  /* identical to box height */

  letter-spacing: -0.02em;

  color: #252525;
}

.faq_title {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  height: 34px;
  color: #272727;
  transition: 0.67s color;
}

.faq_description {
  color: rgb(39, 39, 39);
}

.faq_description_padding {
  padding-top: 10px;
  padding-bottom: 20px;
}

.faq_description_p {
  white-space: nowrap;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.007em;
  color: #272727;
  transition: all 0.4s ease;
  overflow: hidden;
}

.processInfographic_container {
  position: relative;
  width: 1257.7px;
  height: 507.37px;
}

.processInfographic_outline {
  position: absolute;
  border-color: #2c5890;
  border-width: 1px;
  border-style: solid;
  /* background-color: lightgray; */
  z-index: 1;
}

.processInfographic_outline_red {
  position: absolute;
  border-bottom: none;
  border-color: red;
  border-width: 2px;
  border-style: solid;
  z-index: 99;
  opacity: 0;
  transition: all 0.3s ease;
}

.processInfographic_outline_red_1 {
  width: 230px;
  height: 167px;
  border-radius: 10px 0 0 0;
  top: 59px;
  border-bottom: none;
  border-right: none;
}

.hover > .processInfographic_outline_red_1 {
  opacity: 0.75;
}

.processInfographic_outline_red_2 {
  width: 35px;
  height: 35px;
  border-radius: 0 10px 0 0;
  top: 59px;
  left: 230px;
  border-left: none;
  border-bottom: none;
}

.hover > .processInfographic_outline_red_2 {
  opacity: 0.75;
}

.processInfographic_outline_red_3 {
  width: 203px;
  height: 413px;
  border-radius: 0px 0px 0 10px;
  border-top: none;
  border-right: none;
  left: 263px;
  bottom: 0;
}

.hover > .processInfographic_outline_red_3 {
  opacity: 0.75;
}

.processInfographic_outline_red_4 {
  width: 30px;
  height: 30px;
  border-radius: 0 0 10px 0;
  bottom: 0;
  left: 466px;
  border-left: none;
  border-top: none;
}

.hover > .processInfographic_outline_red_4 {
  opacity: 0.75;
}

.processInfographic_outline_red_5 {
  width: 234px;
  height: 477px;
  border-radius: 10px 0 0 0;
  border-bottom: none;
  border-right: none;
  left: 494px;
}

.hover > .processInfographic_outline_red_5 {
  opacity: 0.75;
}

.processInfographic_outline_red_6 {
  width: 31px;
  height: 31px;
  border-radius: 0 10px 0 0;
  border-bottom: none;
  border-left: none;
  left: 728px;
}

.hover > .processInfographic_outline_red_6 {
  opacity: 0.75;
}

.processInfographic_outline_red_7 {
  width: 203px;
  height: 476px;
  border-radius: 0px 0px 0 10px;
  border-top: none;
  border-right: none;
  left: 757px;
  bottom: 0;
}

.hover > .processInfographic_outline_red_7 {
  opacity: 0.75;
}

.processInfographic_outline_red_8 {
  width: 31px;
  height: 30px;
  border-radius: 0 0 10px 0;
  border-top: none;
  border-left: none;
  left: 960px;
  bottom: 0;
}

.hover > .processInfographic_outline_red_8 {
  opacity: 0.75;
}

.processInfographic_outline_red_9 {
  width: 234px;
  height: 418px;
  border-radius: 10px 0px 0px 0px;
  border-bottom: none;
  border-right: none;
  left: 989px;
  top: 60px;
}

.hover > .processInfographic_outline_red_9 {
  opacity: 0.75;
}

.processInfographic_outline_red_10 {
  width: 31px;
  height: 212px;
  border-radius: 0px 10px 0px 0px;
  border-bottom: none;
  border-left: none;
  right: 4px;
  top: 60px;
}

.hover > .processInfographic_outline_red_10 {
  opacity: 0.75;
}

.processInfographic_outline_1 {
  width: 264px;
  height: 166px;
  border-radius: 10px 10px 0px 0px;
  border-bottom: none;
  top: 60px;
}

.hover > .processInfographic_outline_mobile {
  border-color: red;
}

.processInfographic_outline_mobile_1 {
  width: 94px;
  height: 222px;
  border-radius: 0px 10px 10px 0px;
  border-left: none;
  top: -17px;
  right: 15px;
}
.processInfographic_outline_mobile_1b {
  width: 94px;
  height: 240px;
  border-radius: 10px 0px 0px 10px;
  border-right: none;
  top: 204px;
  left: 22px;
}

.processInfographic_outline_mobile_2a {
  width: 94px;
  height: 244px;
  border-radius: 0px 10px 10px 0px;
  border-left: none;
  top: 443px;
  right: 15px;
}
.processInfographic_outline_mobile_2b {
  width: 94px;
  height: 230px;
  border-radius: 10px 0px 0px 10px;
  border-right: none;
  top: 686px;
  left: 22px;
}

.processInfographic_outline_mobile_3 {
  width: 94px;
  height: 244px;
  border-radius: 0px 10px 10px 0px;
  border-left: none;
  top: 915px;
  right: 15px;
}

/* .hover > .processInfographic_outline_1 {
  border-width: 2px;
  border-color: red;
} */

.hover .processInfographic_outline_h5 {
  color: #0085ff;
  transition: all 0.3s ease;
}

.hover .processInfographic_outline_icon {
  filter: invert(72%) sepia(77%) saturate(781%) hue-rotate(161deg)
    brightness(100%) contrast(88%);
}

.processInfographic_wave {
  opacity: 0.05 !important;
  filter: invert(72%) sepia(77%) saturate(781%) hue-rotate(161deg)
    brightness(100%) contrast(88%);
}

.hover .processInfographic_wave {
  opacity: 0.3 !important;
  filter: none;
  transition: all 0.3s ease;
}

.processInfographic_outline_2 {
  width: 233px;
  height: 280px;
  border-radius: 0px 0px 10px 10px;
  border-top: none;
  left: 263px;
  top: 226px;
}

.processInfographic_outline_1_1 {
  position: absolute;
  width: 922px;
  border-radius: 10px 10px 0px 0px;
  border-bottom: none;
  left: -660px;
  z-index: 0;
}

.processInfographic_outline_2_1 {
  position: absolute;
  width: 233px;
  left: 263px;
  z-index: 0;
}

.processInfographic_outline_3 {
  width: 264px;
  height: 226px;
  border-radius: 10px 10px 0px 0px;
  border-bottom: none;
  left: 495px;
}

.processInfographic_outline_3_1 {
  position: absolute;
  width: 264px;
  left: 495px;
  z-index: 0;
}

.processInfographic_outline_4 {
  width: 233px;
  height: 280px;
  border-radius: 0px 0px 10px 10px;
  border-top: none;
  left: 758px;
  top: 226px;
}
.processInfographic_outline_4_1 {
  position: absolute;
  width: 233px;
  left: 758px;
  z-index: 0;
}

.processInfographic_outline_5 {
  width: 264px;
  height: 166px;
  border-radius: 10px 10px 0px 0px;
  border-bottom: none;
  left: 990px;
  top: 60px;
}

.processInfographic_outline_5_1 {
  position: absolute;
  width: 922px;
  left: 990px;
  z-index: 0;
}

.processInfographic_outline_extra {
  width: 264px;
  height: 46px;
  border-bottom: none;
  border-top: none;
  border-left: none;
  left: 990px;
  top: 226px;
}

.processInfographic_info {
  position: absolute;
  text-align: center;
}

.processInfographic_info_1 {
  left: 35px;
  top: 112px;
}

.processInfographic_info_2 {
  left: 295px;
  top: 185px;
}

.processInfographic_info_3 {
  left: 522px;
  top: 53px;
}

.processInfographic_info_4 {
  left: 780px;
  top: 185px;
}

.processInfographic_info_5 {
  left: 1030px;
  top: 112px;
}

.processInfographic_outline_icon {
  margin-bottom: 21.58px;
  width: 137px;
  transition: all 0.3s ease;
}

.processInfographic_outline_h5 {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height */

  color: #000000;
  margin-bottom: 18.62px;
}

.processInfographic_outline_p {
  font-weight: 450;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #0c0c0c;
}

.casestudies_h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 59px;
  /* identical to box height */

  color: #252525;
  padding-bottom: 48px;
}

.startup_h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 59px;

  color: #272727;
  margin-bottom: 58px;
}

.startup_description {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  /* or 150% */
  letter-spacing: 0.007em;
  color: #272727;
  margin-bottom: 144px;
}

.startup_outcome {
  text-align: center;
}

.startup_outcome_h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.02em;

  color: #252525;

  margin-bottom: 40px;
}

.startup_outcome_p {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* or 150% */

  text-align: center;
  letter-spacing: 0.007em;

  color: #272727;
}

.startup_steps {
  text-align: left;
  width: 1072px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.startup_steps_row {
  position: relative;
  width: 1296px;
}

.startup_steps span {
  white-space: nowrap;
}

.startup_steps_content_description {
  align-self: center;
}

.startup_steps_content_description:first-child {
  text-align: right;
}

.startup_steps_h1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -0.02em;
}

.startup_steps_h6 {
  font-weight: 700;
  font-size: 24px;
  /* line-height: 134px; */
  height: 33px;
  white-space: nowrap;
}

.hover .startup_steps_h6 {
  color: #0085ff;
  transition: all 0.3s ease;
}

.startup_steps_p {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* or 150% */

  letter-spacing: 0.007em;
  color: #272727;
}

.startup_steps_content {
  display: flex;
  position: relative;
  justify-content: center;
}

.startup_steps_content_img {
  width: 472px;
  height: 430px;
  border-radius: 5px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.startup_steps_content_borderBox {
  position: absolute;
  border-color: #2c5890;
  border-width: 1px;
  border-style: solid;
}

.borderBox1 {
  border-radius: 0 15px 15px 0;
  border-left: none;
  width: 128px;
  height: 447px;
  top: -65px;
  left: 645px;
}

.hover > .processInfographic_outline_red.borderBox1,
.hover > .processInfographic_outline_red.borderBox2,
.hover > .processInfographic_outline_red.borderBox3,
.hover > .processInfographic_outline_red.borderBox4,
.hover > .processInfographic_outline_red.borderBox5,
.hover > .processInfographic_outline_red.borderBox6 {
  opacity: 0.75;
}

.borderBox2 {
  border-radius: 15px 0 0 15px;
  border-right: none;
  width: 217px;
  height: 392px;
  top: -47px;
  left: 453px;
}

.processInfographic_outline_red.borderBox2 {
  top: -48px;
}

.borderBox3 {
  border-radius: 0 15px 15px 0;
  border-left: none;
  width: 174px;
  height: 446px;
  top: -66px;
  left: 645px;
}

.processInfographic_outline_red.borderBox3 {
  top: -68px;
  height: 448px;
}

.borderBox4 {
  border-radius: 15px 0 0 15px;
  border-right: none;
  width: 214px;
  height: 392px;
  top: -55px;
  left: 431px;
}

.processInfographic_outline_red.borderBox4 {
  top: -56px;
  height: 393px;
}

.borderBox5 {
  border-radius: 0 15px 15px 0;
  border-left: none;
  width: 164px;
  height: 475px;
  top: -58px;
  left: 605px;
}

.processInfographic_outline_red.borderBox5 {
  width: 125px;
  left: 645px;
  top: -59px;
  height: 476px;
}

.processInfographic_outline_red.borderBox6 {
  border-left: none;
  border-right: none;
  border-top: none;
  width: 40px;
  left: 605px;
  bottom: -74px;
}

.startup_steps_pitch {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height */

  letter-spacing: -0.02em;

  color: #252525;
  padding-right: 300px;
}

/**/
.solutions_heading_h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 59px;
  text-align: center;

  color: #272727;
  margin-bottom: 58px;
}

.solutions_heading_description {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* or 150% */
  text-align: center;
  letter-spacing: 0.007em;
  color: #272727;
  margin-bottom: 118px;
}

.solutions_heading_outcome {
  text-align: center;
}

.solutions_heading_outcome_h4 {
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  letter-spacing: -0.02em;
  color: #252525;

  margin-bottom: 40px;
}

.solutions_heading_outcome_p {
  color: #272727;
  letter-spacing: 0.007em;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.solutions_center_tabs {
  display: flex;
  justify-content: center;
  width: 693px;
  margin: 0 auto;
  position: relative;
}

.solutions_center_tabs_h5 {
  font-weight: 400;
  font-size: 28px;
  line-height: 134px;

  color: #272727;

  cursor: pointer;
}

.solutions_center_tabs_line {
  width: 816px;
  height: 2px;
  background-color: #707070;
  position: absolute;
  top: 100px;
}

.solutions_center_tabs_selected {
  height: 5px;
  position: absolute;
  top: -3px;

  transition: all 0.4s ease;

  background-color: #fd0000;
  width: 206px;
  left: 0px;
}

.solutions_center_tabs_selected.Strategy {
  background-color: #fd0000;
  width: 206px;
  transform: translateX(0);
}

.solutions_center_tabs_selected.Design {
  background-color: #006cfd;
  width: 148px;
  transform: translateX(235px);
  left: -2px;
}

.solutions_center_tabs_selected.Development {
  background-color: #fd0000;
  width: 285px;
  transform: translateX(408px);
}
.solutions_center_tabs_selected.Ai {
  background-color: #fd0000;
  width: 124px;
  transform: translateX(693px);
}

.solutions_center_content {
  margin-top: 115px;
}

.solutions_center_content_section {
  display: flex;
}

.solutions_center_content_h5 {
  color: #272727;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;

  white-space: nowrap;

  margin-bottom: 30px;
}

.solutions_center_content_p {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* or 150% */

  letter-spacing: 0.007em;
  color: #272727;
  width: 393px;
}

.solutions_center_content_img {
  width: 531px;
  height: 329px;
  border-radius: 5px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.solutions_center_content_capabilities {
  position: relative;
}

.solutions_center_content_capabilities_h6 {
  font-weight: 600;
  font-size: 25px;
  color: #272727;
  /* position: absolute; */
}

.solutions_center_content_capabilities_description {
  color: rgb(39, 39, 39);

  white-space: nowrap;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.007em;
  color: #272727;
  transition: all 0.4s ease;
  overflow: hidden;

  margin: 0 auto;
  width: 897px;
  display: flex;
  flex-wrap: wrap;
}

.solutions_center_content_capabilities_description > .child {
  /* flex: 50%; */
  width: 50%;
}

.solutions_center_content_capabilities_description p {
  margin: 0;
  padding: 0;
}

.solutions_center_content_capabilities_description h6 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;

  color: #252525;
}

.casestudies_template_top {
  width: 467px;
}

.casestudies_template_section {
  white-space: nowrap;
}

.casestudies_template_section.flex {
  display: flex;
}

.casestudies_template_whatLink {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */

  color: #252525;
  text-decoration: none;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  transition: all 0.3s ease;

  margin-bottom: 87px;
}

.casestudies_template_whatLink:hover {
  filter: invert(72%) sepia(77%) saturate(781%) hue-rotate(161deg)
    brightness(100%) contrast(88%);
}

.casestudies_template_whatLink p {
  margin: 0;
}

.casestudies_template_h1 {
  font-weight: 700;
  font-size: 48px;
  color: #272727;
  letter-spacing: -0.02em;
}

.casestudies_template_h1.challenge {
  margin-bottom: 32px;
  font-size: 32px;
}

.casestudies_template_h2 {
  font-weight: 700;
  font-size: 32px;
  letter-spacing: -0.02em;
  color: #252525;
}

.casestudies_template_h4 {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -0.02em;
  color: #252525;
}

.casestudies_template_h5 {
  font-weight: 700;
  font-size: 32px;
  letter-spacing: -0.02em;
  color: #252525;
}

.casestudies_template_h6 {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.007em;
  color: #272727;
  white-space: nowrap;
}

.casestudies_template_subtitles {
  font-weight: 500;
  font-size: 24px;
  line-height: 45px;
  /* or 180% */

  letter-spacing: 0.007em;

  color: #272727;
}

.casestudies_template_p {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.007em;
  color: #272727;
}

.casestudies_template_img {
  border-radius: 5px;
}

.casestudies_template_headingImg_container {
  width: 1082px;
  /* height: 480px; */
  background-color: gray;
  border-radius: 10px;
  margin-bottom: 78px;
}

.casestudies_template_h2_deliverables {
  margin-bottom: 15px;
}

.casestudies_template_headingImg {
  width: 1082px;
  height: 480px;
  border-radius: 10px;
}

.casestudies_template_services {
  font-weight: 500;
  font-size: 24px;
  line-height: 45px;
  color: #272727;
  width: 455px;
}

.casestudies_template_challengeImg_container {
  width: 960px;
  height: 500px;
  /* background-color: gray; */
  border-radius: 10px;
}

.casestudies_template_deliverableImg_container {
  width: 578px;
  height: 454px;
  margin-right: 112px;
  border-radius: 10px;
}

.casestudies_template_section.flex.casestudies_template_deliverables
  > div:nth-child(2) {
  margin-top: -20px;
}

.aboutus_h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 59px;
  /* identical to box height */

  letter-spacing: -0.02em;
}

.aboutus_p {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* or 150% */

  letter-spacing: 0.007em;
  white-space: 'nowrap';
}

.aboutus_p.aboutus_heading {
  margin-top: 63px;
}

/* FadingLogos */
.fadingLogos_container {
  position: relative;
  display: block;
  width: 100%;
  max-width: 400px;
  height: 280px;
  margin: 0 auto;
}

.fadingLogos_pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* 
n = 2
a = 3
b = 2 

t = (a + b) * n = (3 + 2) * 2 = 10 seconds

animation-delay = a + b = 3 + 2 = 5 seconds

1. 0%
2. a/t * 100% = 3/10 * 100% = 30%
3. 1/2 * 100% = 1/4 * 100% = 50%
4. 100% - (b/t * 100%) = 100% - (2/10 * 100%) = 100% - 10% = 80%
5. 100%
*/

@keyframes fade {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* @-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  33% {
    opacity: 1;
  }
  53% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  33% {
    opacity: 1;
  }
  53% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
} */

#root
  > main
  > div.container
  > div
  > div.startup_steps
  > section
  > div:nth-child(5)
  > div.startup_steps_content {
  padding-right: 200px;
}

.startup_steps > section > div:nth-child(3) {
  padding-right: 200px;
}

.startup_steps > section > div:nth-child(6) {
  padding-right: 50px;
}
.startup_steps > section > div:nth-child(1) > h1 {
  padding-left: 150px;
}

.startup_steps > section {
  display: flex;
  flex-direction: column;
}

.fadein {
  position: absolute;
  /* top: 0px; */
}
.fadein img {
  /* opacity: 0; */
  opacity: 1;
  position: absolute;
  -webkit-animation-name: fadein;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 6s;
  animation-name: fadein;
  animation-iteration-count: infinite;
  animation-duration: 6s;
}

.fadein > img:nth-child(2) {
  animation-delay: 3s;
}

.hamburger {
  display: none;
  background: url('./img/misc/hamburger.svg') !important;
  background-repeat: no-repeat;
  background-position: center;
  height: 17px;
  width: 30px;
  border: none;
}

.navbar-toggler {
  font-size: unset !important;
  border: none !important;
}

.basic-navbar-nav:focus {
  border: none !important;
}

.aboutus_process {
  margin-top: 126px;
}

.aboutus.flexContainer {
  margin-top: 64px;
  margin-bottom: 125.75px;
}

.startup.flexContainer {
  margin-top: 129px;
  margin-bottom: 185px;
}

.solutions_center_tabs > h5:nth-child(2) {
  margin-left: 100px;
  margin-right: 100px;
}
.solutions_center_tabs > h5:nth-child(3) {
  margin-right: 100px;
}

.solutions_process {
  margin-top: 149px;
}

.casestudies_template_section_w1460 {
  width: 958px;
}

.casestudies_template_deliverables {
  margin-top: 100px;
}

.casestudies_template_heading {
  margin-bottom: 59px;
}

.casestudies_template_title {
  margin-bottom: 40px;
}

.homepage_video {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.homepage_play_container {
  width: 1280px;
  height: 720px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  /* top: 55%;
  right: 45%; */
}

.homepage_play {
  width: 100px;
  height: 100px;
  cursor: pointer;
  z-index: 99;
}

.backdrop {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.9
  ); /* Set opacity to 0.5 for a dark overlay */
  z-index: 0; /* Set z-index to ensure the backdrop is on top of other elements */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease;
}

.backdrop.red {
  background: url('./img/misc/menu-bg.png');
  background-position: right 0px top 0px;
  background-repeat: no-repeat;
  background-size: cover;
}

.backdrop.show {
  visibility: visible;
  opacity: 1;
  z-index: 9999;
}

.youtube {
  width: 1000px;
  height: 563px;
}

.close-icon {
  position: absolute;
  top: 50px;
  right: 30px;
  color: #fff;
  cursor: pointer;
  font-size: 30px;
}

.logos_hvakojo {
  left: 0px;
}

.logos_cargormit {
  left: 170px;
}

.logos_anzboa {
  left: 395px;
  bottom: -10px;
}

.logos_auschar {
  left: 600px;
  bottom: -5px;
}

.logos_yalumbaagl {
  left: 800px;
  bottom: 12px;
}

.logos_bupanab {
  left: 1000px;
}

.svg-logos {
  position: absolute;
  /* display: none; */
}

.logo_svg {
  height: auto;
  position: absolute;
  opacity: 1;
  transition: all 1s ease;
}

.logo_svg.hide path,
.logo_svg.hide g,
.logo_svg.hide rect,
.logo_svg.hide {
  opacity: 0;
}

#hva {
  width: 100px;
}
#cargo {
  width: 115px;
}
#anz {
  width: 124px;
}
#auspost {
  width: 121px;
}
#yalumba {
  width: 123px;
}
#bupa {
  width: 59px;
}

#kojo {
  width: 86px;
}
#rmit {
  width: 162px;
  margin-left: -10px;
}
#boa {
  width: 148px;
}
#standchar {
  width: 161px;
}
#agl {
  width: 88px;
}
#nab {
  width: 135px;
}

.logo_path,
path,
g,
rect {
  transition: all 3s ease;
}

#yalumba .cls-1 {
  fill: none;
}

#yalumba .cls-2 {
  fill: #fff;
}

#yalumba .cls-3 {
  fill: #626262;
}

#yalumba .cls-4 {
  fill: #626166;
}

#bupa .cls-2 {
  fill: #fff;
}

#bupa .cls-3 {
  fill: #616266;
}

#nab .cls-2 {
  fill: #626166;
}

.td-1 {
  transition-delay: 0ms;
}
.td-2 {
  transition-delay: 100ms;
}
.td-3 {
  transition-delay: 200ms;
}
.td-4 {
  transition-delay: 300ms;
}
.td-5 {
  transition-delay: 400ms;
}
.td-6 {
  transition-delay: 500ms;
}
.td-7 {
  transition-delay: 600ms;
}
.td-8 {
  transition-delay: 700ms;
}

.reactplayer_heading {
  width: 100%;
  height: 100%;
}

/* .what_we_do:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  transform: translateY(-2px); 
} */

/* .what_we_do #color-bar {
  width: 13px !important;
} */

/* .what_we_do #card-title {
  color: #464646;
} */

.what_we_do {
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth hover effect for shadow and scale */
}

.what_we_do:hover {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Optional: Slight shadow for hover */
  transform: translateY(-5px); /* Slight upward movement */
}

#color-bar {
  width: 5px;
  transition: width 0.3s ease, background-color 0.3s ease;
}

.what_we_do:hover #color-bar {
  width: 13px;
}

#card-title {
  transition: color 0.3s ease; /* Smooth transition for the title color */
}

.what_we_do:hover #card-title {
  color: var(--hover-color); /* Ensure the hover color is dynamically applied */
}

.hover-image-container {
  position: relative;
}

.hover-image {
  position: absolute;
  display: flex;
  opacity: 1;
  z-index: 20;
}

.dynamic-image {
  /* z-index: -10;
  transition: clip-path 0.3s ease, box-shadow 0.3s ease; */
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  transition: filter 0.9s ease-in-out;
}

.dynamic-image:hover {
  box-shadow: 0 0 40px 40px rgba(0, 0, 0, 0.5);
}

.clip-path {
  clip-path: circle(400px at 50% 50%);
}

@media only screen and (min-width: 993px) {
  .ham {
    visibility: hidden;
    display: none !important;
  }
  .w414 {
    visibility: hidden;
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .w1460 {
    visibility: hidden;
    display: none !important;
  }
  .w414 {
    visibility: visible;
    display: block;
  }
  footer .footer_email {
    margin-top: 0;
  }
  footer .footer_phone {
    margin-top: 0;
  }
  footer .footerLinks {
    margin-top: 0;
  }

  .homepage_play_container {
    width: 100%;
    height: 100%;
  }
  .homepage_play {
    width: 50px;
    height: 50px;
  }
  .footer_h1 {
    font-size: 36px;
    line-height: unset;
  }
  .footer_email,
  .footer_phone {
    font-size: 16px;
  }
  .footer_email {
    margin-top: 16px;
  }
  .footer_phone {
    margin-top: 23px;
  }
  .footerAddress {
    margin-top: 26px;
  }
  .footer_socials {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  .footerAddress,
  .footer_p {
    font-size: 13px;
  }
  .hamburger {
    display: unset;
  }
  .navBarLinks {
    display: flex;
    padding-left: 60px;
    width: 100vw;
    height: 100vh;
    transition: all 0.4s ease;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-around;
  }
  .nav_jxLogo {
    width: 79px;
    height: 31px;
    background-image: url('./img/misc/jxdesign.svg');
  }
  .navLink {
    padding: 0;
    margin: 0;
  }
  .navBar {
    box-shadow: none;
    padding: 0 !important;
    /* height: 100px; */
  }
  .navRight {
    margin: 0;
  }

  .nav-link {
    font-size: 17px;
    color: white !important;
    transition: all 0.3s ease;
  }
  .navbar-nav .nav-link {
    padding: 0;
  }
  .nav-link:hover,
  .navbar_ham_talk > .footer_email:hover,
  .navbar_ham_talk > .footer_phone:hover {
    opacity: 0.5;
    color: white !important;
  }
  footer {
    padding-top: 42px;
    padding-bottom: 62px;
    width: 100%;
    /* max-width: 414px; */
  }

  .footerContainer {
    width: 414px;
  }
  .footer_copyright {
    margin-left: 0;
    margin-top: 10px;
  }
  .footerLinks {
    display: flex;
    flex-direction: column;
  }
  .footerLinksContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
  }
  .footer_jxLogo {
    height: 45px;
  }
  .footerTopRight {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .jx_digital_design_agency_servicedesign_uxui_anchor {
    align-self: baseline;
  }
  .footer_loonumLogo {
    width: 140px;
    height: fit-content;
  }
  .footerTopRight > a {
    margin-right: 16px;
  }
  .footerLocations {
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
  }
  .footerLocations > li {
    line-height: unset;
    text-align: right;
  }
  .homepage.container {
    width: 414px;
    padding: 0;
  }
  .homepage_heading_h1 {
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    letter-spacing: -0.02em;
  }
  .homepage_heading_ipadStockPhoto {
    margin-top: 39px;
    cursor: pointer;
  }
  .homepage_what_h4 {
    margin-right: 0;
  }
  .homepage_what_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 0px;

    display: flex;
    align-items: center;
  }
  .homepage_what {
    flex-direction: column;
    margin-top: 41px;
    margin-left: auto;
  }
  .homepage_what_description_div {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .homepage_what > div:nth-child(2) {
    margin-top: 19px;
  }
  .homepage_process_h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */

    text-transform: capitalize;

    color: #252525;
    margin-bottom: 27px;
  }
  .homepage_process {
    margin: 0;
  }
  .homepage_process_p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */

    letter-spacing: 0.007em;

    color: #272727;
  }
  .homepage_clients_h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: -0.02em;
  }
  .homepage_clients {
    margin-top: 57px;
    margin-left: 0;
  }
  .homepage_cases_h4 {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */
    color: #252525;
    margin-left: 0px;
  }
  .homepage_cases_container {
    margin-left: 0;
    margin-top: 42px;
  }
  .homepage_cases_img {
    width: 302px;
  }
  .homepage_cases_title {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;

    color: #252525;
  }
  .homepage_cases_subtitle {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */

    text-transform: capitalize;

    color: #6b6b6b;
  }
  .homepage_cases_container {
    flex-direction: column;
    /* align-items: center; */
  }
  .homepage_aboutus {
    margin-top: 0;
    margin-left: 0;
    flex-direction: column;
  }
  .homepage_aboutus_h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */

    letter-spacing: -0.02em;

    color: #252525;
  }
  .homepage_aboutus_p {
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    /* or 175% */

    letter-spacing: 0.007em;

    color: #272727;
  }
  .homepage_aboutus > .col:nth-child(2) {
    margin-top: 40px !important;
  }
  .homepage_heading_title,
  .homepage_what,
  .homepage_process,
  .homepage_cases,
  .homepage_aboutus,
  .homepage_clients {
    padding: 0 60px;
  }
  .homepage_what_description_p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */

    letter-spacing: 0.007em;
    margin-bottom: -25px;
  }
  .processInfographic_container {
    position: relative;
    width: 225px;
    height: 1060px;
    background-color: #002e6c;
  }

  .processInfographic_info {
    position: unset !important;
  }
  .processInfographic_outline_h5 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
  .processInfographic_outline_p {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
  }
  .processInfographic_outline_icon {
    width: 79px;
  }
  .homepage_process_processInfographic {
    display: flex;
    justify-content: center;
    margin-top: 52px;
    height: 1200px;
  }
  .processInfographic_container > div:not(div:first-child) {
    margin-top: 42px;
  }
  .homepage_clients_logoContainer {
    margin-top: 60px;
  }
  .homepage_cases {
    margin-top: 400px;
  }
  .homepage_cases_container > div:nth-child(1) > section:nth-child(2) {
    margin-bottom: 64px;
  }
  .years_p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
  .years_h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */

    letter-spacing: -0.02em;
  }
  div.homepage_cases_container > div:nth-child(1) > section:nth-child(2) {
    margin-top: 78px;
  }
  .aboutus_h1 {
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    /* identical to box height */

    letter-spacing: -0.02em;
  }
  .aboutus_p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */

    letter-spacing: 0.007em;
  }
  .aboutus_p.aboutus_heading {
    margin-top: 36px;
  }
  .aboutus_founder {
    flex-direction: column;
  }
  .aboutus_founder_h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
  }
  .about_founder_description {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */

    letter-spacing: 0.007em;
  }
  .about_founder_img {
    margin: 0;
  }
  .about_founder_img img {
    width: 304px;
    height: 325px;
    margin-right: 0;
  }
  .aboutus_founder_content {
    width: auto;
  }
  .aboutus_founder_h1.w414 {
    margin-bottom: 36px;
  }
  .aboutus_founder {
    margin-top: 33px;
    margin-bottom: 54px;
  }
  .about_team_h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.02em;
    text-align: left;
  }
  .about_team {
    margin-top: 54px;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .about_team_img {
    width: 304px;
    height: 361px;
  }
  section.about_team > div:nth-child(2) {
    margin-left: 0;
    margin-right: 0;
  }
  .about_team_text {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
  }
  .about_team_subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
  .about_team_member {
    margin-bottom: 0px;
  }
  .about_values {
    margin-top: 81px;
  }
  .about_values_h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */

    letter-spacing: -0.02em;
    align-self: baseline;
  }
  .about_values_map {
    display: flex;
    flex-direction: column;
    width: auto;
    row-gap: 0;
    padding-top: 58px;
  }
  .about_values_title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    letter-spacing: -0.02em;
  }
  .about_values_description {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 30px;
    margin-right: 0;
    height: auto;
  }
  .aboutus_process {
    margin-top: 67px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .aboutus_process_h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
  }
  .aboutus.flexContainer {
    margin-top: 54px;
  }
  .clientFooter {
    padding-top: 58px;
    height: 758px;
  }
  .clientFooter_h5 {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: -0.02em;
  }
  .clientFooter_logoContainer {
    margin-top: 58px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
  .clientFooter_logoContainer > img {
    margin: 0 !important;
  }
  .processInfographic_container {
    margin: 0 auto;
  }
  .startup_h1 {
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    /* identical to box height */

    letter-spacing: -0.02em;
  }
  .startup_description {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */

    letter-spacing: 0.007em;
    margin-bottom: 54px;
  }
  .startup_steps_h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
  }
  .startup_steps_h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    letter-spacing: -0.02em;
  }
  .startup_steps_p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
  .startup_steps_pitch {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    white-space: nowrap;
    padding-right: 150px;
  }
  .startup_outcome_h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.02em;
  }
  .startup_outcome_p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */

    text-align: center;
  }
  .flexContainer.startup img,
  .startup_steps_content_img {
    display: none;
    visibility: hidden;
  }
  .startup_steps_content_container,
  .startup_steps_row {
    margin: 0 !important;
    padding: 0 !important;
  }
  .startup_steps_row,
  .startup_steps {
    width: auto;
  }
  .startup_steps_content:not(.startup_steps > div:nth-child(7) > div) {
    margin-bottom: 96px;
  }
  .startup_steps > div:nth-child(7) > div {
    margin-bottom: 15px;
  }
  .startup_outcome {
    margin-top: 27px;
  }
  .flexContainer.startup {
    margin-top: 54px;
    margin-bottom: 54px;
  }
  .solutions_heading_h1 {
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    /* identical to box height */

    letter-spacing: -0.02em;
    margin-bottom: 36px;
  }

  .solutions_heading_description {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */

    letter-spacing: 0.007em;
    margin-bottom: 70px;
  }
  .solutions_center_tabs_h5 {
    font-size: 15px;
    line-height: 0px;
  }
  .solutions_center_tabs > h5:nth-child(2) {
    margin-left: 42px;
    margin-right: 42px;
  }
  .solutions_center_tabs_line {
    width: 340px;
    top: 15px;
  }
  .solutions_center_tabs_selected.Strategy {
    width: 100px;
  }
  .solutions_center_tabs_selected.Development {
    width: 150px;
    transform: translateX(190px);
  }
  .solutions_center_tabs_selected.Design {
    width: 100px;
    transform: translateX(100px);
  }

  .solutions_center_tabs_selected.Ai {
    width: 100px;
    transform: translateX(200px);
  }
  .solutions_center_content {
    margin-top: 65px;
  }
  .solutions_center_content_h5 {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.02em;
  }
  .solutions_center_content_p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
  .solutions_center_content_img {
    width: 309px;
    height: 191px;
    margin-bottom: 36px;
  }
  .solutions_center_content_section {
    flex-direction: column;
  }
  .solutions_center_content_p {
    width: 304px;
  }
  .solutions_center_tabs {
    width: auto;
  }
  .solutions_center_content_img,
  .solutions_center_content > .solutions_center_content_img > div:nth-child(1),
  .solutions_center > div.solutions_center_content > section > div:nth-child(1),
  .solutions_center_content_section > div:first-child {
    margin-right: 0px !important;
  }
  .solutions_center_content
    > .solutions_center_content_section:nth-child(even) {
    flex-direction: column-reverse;
  }
  .solutions_center_content_section {
    margin-bottom: 0 !important;
  }
  .solutions_center_content_capabilities_h6 {
    font-weight: 600;
    font-size: 15px;
    line-height: 0px;
    margin-bottom: 19px;
  }
  .solutions_process {
    margin-top: 56px;
    margin-bottom: 47px;
  }
  .casestudies_h1 {
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    /* identical to box height */

    letter-spacing: -0.02em;
    white-space: nowrap;
    margin-left: 0;
  }
  .caseStudies2 {
    margin-top: 54px;
  }
  .faq_h1 {
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    /* identical to box height */

    letter-spacing: -0.02em;
  }
  .faq_description_p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */

    letter-spacing: 0.007em;
  }
  .faq_description_padding {
    padding: 0;
  }
  .faq_title {
    font-weight: 500;
    font-size: 16px;
    line-height: unset;
  }
  div.faq_container.container
    > div
    > section:nth-child(6)
    > div.faq_description_padding
    > div.faq_description
    > p.faq_description_p,
  div.faq_container.container
    > div
    > section:nth-child(9)
    > div.faq_description_padding
    > div.faq_description
    > p.faq_description_p,
  div.faq_container.container
    > div
    > section:nth-child(12)
    > div.faq_description_padding
    > div.faq_description
    > p.faq_description_p {
    padding-top: 30px;
  }
  .casestudies_template_whatLink {
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
  }
  .casestudies_template_h1 {
    font-weight: 700;
    font-size: 23px;
    line-height: 28px;

    letter-spacing: -0.02em;
  }
  .casestudies_template_h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    letter-spacing: -0.02em;
  }
  .casestudies_template_h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 45px;

    letter-spacing: 0.007em;
  }
  .casestudies_template_section_heading {
    width: auto;
  }

  main
    > div.flexContainer
    > section:nth-child(3)
    > div.casestudies_template_top {
    margin-bottom: 15px;
  }

  .casestudies_template_serviceprovided {
    margin-bottom: 10px !important;
  }
  .casestudies_template_overview {
    margin-top: 50px;
  }
  .casestudies_template_deliverables {
    margin-top: 35px !important;
  }
  .casestudies_template_h2_deliverables {
    margin-top: 66px;
  }

  main
    > div.flexContainer
    > section:nth-child(3)
    > div.casestudies_template_top {
    margin-right: 0 !important;
  }
  .casestudies_template_section.flex {
    flex-direction: column;
  }
  .casestudies_template_top {
    width: auto;
  }
  .casestudies_template_h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
  }
  .casestudies_template_p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */

    letter-spacing: 0.007em;
  }
  .casestudies_template_p > span {
    white-space: normal;
  }
  .casestudies_template_headingImg,
  .casestudies_template_headingImg_container {
    width: 414px;
    height: 184px;
  }
  .casestudies_template_h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
  }
  .casestudies_template_services {
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.007em;
    width: auto;
  }
  .casestudies_template_deliverableImg,
  .casestudies_template_deliverableImg_container {
    width: 309px;
    height: 243px;
    margin-right: 0;
  }
  .casestudies_template_h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    /* or 169% */

    letter-spacing: 0.007em;
  }
  .casestudies_template_subtitles {
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    /* or 169% */

    letter-spacing: 0.007em;
  }
  .casestudies_template_h1.challenge {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
  }
  .casestudies_template_challengeImg,
  .casestudies_template_challengeImg_container {
    width: 374px;
    height: 195px;
  }
  #root > main > div.flexContainer > section:nth-child(7) {
    margin-left: 0 !important;
  }
  .casestudies_template_head > .casestudies_template_h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    letter-spacing: -0.02em;
    margin: 0;
  }
  .casestudies_template_head > .casestudies_template_h6 {
    font-weight: 500;
    font-size: 14px;
    /* line-height: 45px; */
  }

  .faq_container {
    margin-top: 100px;
    margin-bottom: 63px;
  }

  /* LOGOS MOBILE */
  #hva {
    width: 72px;
  }
  #cargo {
    width: 89px;
  }
  #anz {
    width: 103px;
  }
  #auspost {
    width: 90px;
  }
  #yalumba {
    width: 100px;
  }
  #bupa {
    width: 46px;
  }

  #kojo {
    width: 70px;
  }
  #rmit {
    width: 120px;
  }
  #boa {
    width: 120px;
  }
  #standchar {
    width: 140px;
  }
  #agl {
    width: 60px;
  }
  #nab {
    width: 100px;
    display: none;
  }
  .nab_mobileIcon {
    width: 100px;
    height: auto;
    display: block;
  }

  .logos_anzboa {
    left: 0px;
    top: 110px;
  }
  .logos_auschar {
    left: 170px;
    top: 110px;
  }
  .logos_yalumbaagl {
    left: 170px;
    top: 210px;
  }
  .logos_bupanab {
    left: 0px;
    top: 210px;
  }
  .thinIcon::before {
    -webkit-text-stroke: 0px;
  }

  div.faq_container
    > div
    > section:nth-child(12)
    > div.reveal.active
    > div.faq_title
    > p
    > span:nth-child(1) {
    margin-right: -200px !important;
  }
  div.faq_container
    > div
    > section:nth-child(9)
    > div.reveal.active
    > div.faq_title
    > p
    > span:nth-child(1) {
    margin-right: -240px !important;
  }
  div.faq_container
    > div
    > section:nth-child(6)
    > div.reveal.active
    > div.faq_title
    > p
    > span:nth-child(1) {
    margin-right: -180px !important;
  }
  .about_w1460,
  .about_values,
  .aboutus_process,
  .about_team_h3.w414,
  .casestudies_container,
  .casestudies_template_section_w1460 {
    width: 340px;
  }
  .template.flexContainer {
    margin-top: 66px !important;
  }
  .casestudies_template_whatLink {
    margin-bottom: 43px;
  }
  .about_team_h3.w414 {
    margin: 0;
    align-self: center;
  }
  .borderBox1 {
    height: 300px;
    top: -60px;
    left: 180px;
  }
  .borderBox2 {
    height: 285px;
    top: -61px;
    left: -62px;
  }
  .processInfographic_outline_red.borderBox2 {
    top: -62px;
  }
  .borderBox3 {
    width: 120px;
    height: 340px;
    top: -56px;
    left: 160px;
  }
  .processInfographic_outline_red.borderBox3 {
    top: -58px;
    height: 342px;
  }
  .borderBox4 {
    height: 280px;
    top: -59px;
    left: -72px;
  }
  .processInfographic_outline_red.borderBox4 {
    top: -60px;
    height: 281px;
  }
  .borderBox5 {
    width: 180px;
    height: 318px;
    top: -59px;
    left: 86px;
  }
  .processInfographic_outline_red.borderBox5 {
    width: 110px;
    height: 318px;
    top: -60px;
    left: 156px;
  }
  .processInfographic_outline_red.borderBox6 {
    width: 70px;
    left: 86px;
    bottom: -12px;
  }
  div.w414 > div > div:nth-child(6) > div.startup_steps_content {
    margin-bottom: 0px;
  }
  .solutions_center_content_capabilities_description {
    width: auto;
    font-size: unset;
    line-height: unset;
    display: block;
  }
  .solutions_center_content_capabilities_description h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    letter-spacing: -0.02em;
  }
  .solutions_center_content_capabilities_description p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
  .solutions_center_content_capabilities_description > .child {
    width: 100%;
    margin-bottom: 15px;
  }
  .solutions_center_content_capabilities_description {
    padding-top: 15px;
  }
  .navbar_ham_talk {
    display: flex;
    flex-direction: column;
  }
  .navbar_ham_talk a,
  .navbar_ham_talk h1 {
    color: white;
    margin: 0;
    padding: 0;
    line-height: unset;
  }
  .navbar_ham_talk > .footer_email {
    margin-bottom: 37px;
  }
  .navBar_logo_ham {
    position: absolute;
    top: 40px;
    left: 30px;
    width: 200px;
  }
  .casestudies_template_section.flex.casestudies_template_deliverables
    > div:nth-child(2) {
    margin-top: unset;
  }
}

@media (max-width: 768px) {
  .hero-container {
    height: auto !important;
  }
}
