.form-container {
  max-width: 1000px;
  margin: auto;
  padding: 64px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  margin-top: 64px;
  margin-bottom: 96px;
}

.form-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
}

.custom-select {
  height: 50px;
  font-size: 16px;
}

.form-control {
  height: 50px;
  font-size: 16px;
}

textarea.form-control {
  height: 100px;
}

.submit-button {
  background-color: #1c75bc;
  color: white;
  font-size: 18px;
  padding: 10px 50px;
  border-radius: 30px;
  border: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  width: 100%;
}

.submit-button:hover {
  background-color: #0056b3;
}

.form-container label {
  font-weight: 500;
}

.form-container input::placeholder,
.form-container textarea::placeholder {
  color: #cccccc;
}

.form-container input[type="text"],
.form-container input[type="email"],
.form-container textarea,
.form-container select {
  border-radius: 8px;
  border: 1px solid #ced4da;
  padding: 10px 15px;
  font-size: 16px;
  color: #495057;
}

.form-container .form-check-inline {
  margin-right: 20px;
}
