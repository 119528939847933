/* WhatWeDo.css */
.card-container {
  max-width: 1200px;
  padding-bottom: 132px;
}

h2 {
  font-size: 2.5rem;
  font-weight: bold;
}

p {
  font-size: 1rem;
}

.card-title {
  color: #1f2a37;
  font-size: 32px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 35px;
  word-wrap: break-word;
}

.card-text {
  color: #272727;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.11px;
  word-wrap: break-word;
}

.card {
  border: none;
  min-height: 398px;
  transition: transform 0.2s ease-in-out;
  box-shadow: 0px 0px 146.8px 0px rgba(0, 52, 235, 0.13);
}

.card-body {
  padding: 40px 40px 80px 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card:hover {
  transform: translateY(-5px);
}
