@media (max-width: 768px) {
  .homepage_cases_video,
  .homepage_cases_img {
    width: 100%;
    height: auto;
    max-height: 300px;
  }
  .homepage_cases_title {
    font-size: 18px;
  }
  .homepage_cases_subtitle {
    font-size: 14px;
  }
}
