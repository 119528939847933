/* Hide the spans by default on smaller screens */
.span-large {
  display: block;
}

.paragraph-small {
  display: none;
}

/* Media query for screens smaller than 1440px */
@media (max-width: 1440px) {
  .span-large {
    display: none;
  }

  .paragraph-small {
    display: block;
  }
}
